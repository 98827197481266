import { getIsVps } from 'banana-stand/parsers';
import { removeUndefinedKeys } from 'utility/tools/objects';
import { sortedSet, uniqueList } from 'utility/array';
import { bytes, toGibibyte, perMonth } from 'utility/format';
import {
	BUTTON_GROUP_FILTER,
	HARDWARE_FILTER,
	NO_FILTER,
	SLIDER_FILTER,
} from 'utility/constants/hardwareFilters';

// Builds the headersData out of the options. ProductOptionTable needs this.
// doing this dynamically so that it can work for more than just VM if it ever needs to.
const createHeaderData = (options) => {
	return Array.from(
		new Set(
			options.map(({ tableData }) => Object.keys(tableData || {})).flat(),
		),
	)
		.map((headerKey) => {
			switch (headerKey) {
				case 'cpu':
					return { key: headerKey, label: 'CPU(s)', order: 1 };
				case 'cores':
					return { key: headerKey, label: 'Cores / Speed', order: 2 };
				case 'ram':
					return { key: headerKey, label: 'RAM*', order: 3 };
				case 'storage':
					return { key: headerKey, label: 'Storage*', order: 4 };
				case 'price':
					return { key: headerKey, label: 'Price', order: 5 };
				case 'disabled':
					return null;
				default:
					return { key: headerKey, label: headerKey, order: Infinity };
			}
		})
		.filter(Boolean)
		.sort((a, b) => a.order - b.order);
};

const createOptionTileData = (
	{
		value,
		price_total: price,
		extra_data: {
			cpu_description: cpuRaw,
			cpu_speed: speed,
			cores,
			memory,
			storage_type: storageType,
			storage_size: storageSize,
			storage_raid: raid,
			cpu_hyperthreading: hyperthreading,
			category,
		} = {},
	},
	isVPS_, // passed in explicitly (this is not a selector)
	displayType_, // passed in explicitly (this is not a selector)
	disabled,
) => {
	const cpu = cpuRaw?.replace(/\s+/, ' ');
	const storageRaid = `${bytes(storageSize)}${
		storageType ? `, ${storageType}` : ''
	}${raid ? `, Raid-${raid}` : ''}`;
	const tableData = removeUndefinedKeys({
		cores: cores
			? {
					display: `${cores}${
						speed ? ` @ ${Math.round(speed / 100) / 10}GHz` : ''
					}`,
					iconKey: hyperthreading ? 'HyperThreadedIcon' : '',
					value: cores.toString(),
			  }
			: undefined,
		// These properties drive the headers, so making them undefined keeps them from making a header.
		cpu: cpu ? { display: cpu, value: cpu } : undefined,
		ram: memory
			? { display: toGibibyte(memory), value: memory.toString() }
			: undefined,
		storage: storageSize
			? { display: storageRaid, value: storageSize.toString() }
			: undefined,
		price: price
			? { display: perMonth(price), value: price.toString() }
			: undefined,
		disabled,
	});

	const productOptionData = {
		value,
		data: price,
		price,
		listItems: [
			{
				value: `${isVPS_ ? 'vCPUs' : 'Cores'}: ${cores}`,
				key: 'vcpus',
				toolTipText: cpu,
			},
			...(speed
				? [
						{
							value: `Speed: ${speed}MHz`,
							key: 'speed',
						},
				  ]
				: []),
			{ value: `Ram: ${toGibibyte(memory)}`, key: 'ram' },
			{
				value: `Storage: ${storageRaid}`,
				key: 'storage',
			},
		],
		disabled,
		category,
	};

	// These are for filtering the tiles, not for the display.
	const filterProps = {
		cores: Number(cores),
		ram: Number(memory),
		storage: Number(storageSize),
		disk: storageType,
		value, // needed for filtering since we always show the selected value.
	};
	switch (displayType_) {
		case 'ProductOptionTable':
			return { productOptionData, tableData, ...filterProps };
		default:
			// ProductOptionSet
			return { ...productOptionData, ...filterProps };
	}
};

/**
 * @param {Object} config
 * @param {String} productCode
 * @param {String} displayType
 * @returns {{options: Object, selectedExtraData: Object}}
 */
const createOptions = (config, productCode, displayType) => {
	let selectedExtraData = {};
	const configIdOptions = config.options
		?.filter((option) => option.valueString !== '0') // Don't show a selected value of 0 (This is possible if coming from a deploy onto of private parent.)
		.map((option) => {
			if (option.value === config.selectedValue) {
				selectedExtraData = option.extraData;
			}

			return createOptionTileData(
				{
					value: option.value,
					price_total: option.price,
					extra_data: option.extraData,
				},
				getIsVps(productCode),
				displayType,
				option.disabled,
			);
		});

	return { options: configIdOptions, selectedExtraData };
};

const getDiskOptions = (tileData) => {
	const options = uniqueList(tileData?.map(({ disk }) => disk).filter(Boolean));
	return (
		options?.map((option) => ({
			value: option,
			label: option,
		})) || []
	);
};

const getHardwareOptions = (options) => {
	const uniqueCategories = [
		...new Set(
			options
				.filter((option) => option.category)
				.map((option) => option.category),
		),
	];

	return uniqueCategories
		.map((category) => ({
			value: category,
			label: HARDWARE_FILTER[category]?.label ?? category,
			displayOrder: HARDWARE_FILTER[category]?.displayOrder ?? Infinity,
		}))
		.sort((a, b) => a.displayOrder - b.displayOrder);
};

const minOptionsToShowFilters = 5;
const getFilterVariant = (options, productCode = '') => {
	if (!options || options.length < minOptionsToShowFilters) {
		return NO_FILTER;
	}

	const optionsWithCategory = options.filter((option) => option.category);
	if (
		(productCode === 'Cloud.VPS' || productCode === 'Cloud.VPS.WIN') &&
		optionsWithCategory.length > 0
	) {
		return BUTTON_GROUP_FILTER;
	}

	return SLIDER_FILTER;
};

const createFilterProps = (options, productCode, selectedExtraData) => {
	if (!options) return {};

	const coresOptions = sortedSet(options?.map((option) => option.cores));
	const ramOptions = sortedSet(options?.map((option) => option.ram));
	const storageOptions = sortedSet(options?.map((option) => option.storage));
	const diskOptions = getDiskOptions(options);
	const hardwareOptions = getHardwareOptions(options);

	const currentCores = selectedExtraData.cores;
	const currentRam = toGibibyte(selectedExtraData.memory);
	const currentStorage = bytes(selectedExtraData.storage_size);
	const currentHardware = selectedExtraData.category;

	return {
		cores: {
			options: coresOptions,
			virtual: getIsVps(productCode),
			current: currentCores,
		},
		ram: {
			options: ramOptions,
			current: currentRam,
		},
		disk: {
			options: storageOptions,
			diskType: {
				options: diskOptions,
			},
			current: currentStorage,
		},
		hardware: {
			options: hardwareOptions,
			current: currentHardware,
		},
	};
};

const createFilteredOptions = (
	sliderValues,
	options,
	selectedValue,
	current,
) => {
	const {
		cores: coresFilter,
		ram: ramFilter,
		storage: storageFilter,
		hardware: hardwareFilter,
		disk: diskFilter,
		filtersDisabled,
		filterVariant,
	} = sliderValues;
	if (filterVariant === NO_FILTER) return options;

	if (filterVariant === BUTTON_GROUP_FILTER) {
		return options.filter(
			({ category: hardware }) => hardware === hardwareFilter,
		);
	}

	if (filtersDisabled || options.length < minOptionsToShowFilters)
		return options;

	return options.filter(({ cores, ram, storage, disk, value }) => {
		if (value === selectedValue || value?.toString() === current?.toString())
			return true;
		return (
			cores >= coresFilter[0] &&
			cores <= coresFilter[1] &&
			ram >= ramFilter[0] &&
			ram <= ramFilter[1] &&
			storage >= storageFilter[0] &&
			storage <= storageFilter[1] &&
			(diskFilter.length === 0 || diskFilter.includes(disk))
		);
	});
};

// I didn't have a better idea for where this should go.
const optionsFinePrintText =
	'* A portion of the original RAM and storage hardware is reserved for the provisioning system and the remaining amount is displayed';
/**
 * Get full OS name from productCode
 * @param {string} productCode
 * @returns {'window' | 'linux'}
 */
const getOSFromProductCode = (productCode) =>
	/\.WIN$/.test(productCode) ? 'windows' : 'linux';

/**
 * @param {string} supportLevel a Template support level
 */
const parseSupportLevel = (supportLevel) => {
	if (supportLevel === 'Fully-Managed' || supportLevel === 'Core-Managed') {
		return 'Managed';
	}

	return 'Unmanaged';
};

export {
	createHeaderData,
	createOptionTileData,
	createOptions,
	getFilterVariant,
	createFilterProps,
	createFilteredOptions,
	optionsFinePrintText,
	minOptionsToShowFilters,
	getOSFromProductCode,
	parseSupportLevel,
};
