import { call, takeLatest, put, take } from 'redux-saga/effects';
import { actions as productDetailsActions } from 'modules/api/product/detailsModule';
import { actions as sideSheetActions } from 'modules/sideSheet';
import { actions as marketplaceActions } from '..';

function* openSidesheet({
	domain,
	features,
	fullDescription,
	marketplacePrices,
	productCode,
	relatedSubaccnt,
	showConfigure = true,
	title,
	isAddon,
} = {}) {
	yield put(
		sideSheetActions.open({
			title,
			contentKey: 'ProductData',
			contentProps: {
				productCode,
				marketplacePrices,
				fullDescription,
				features,
				showConfigure,
				relatedSubaccnt,
				domain,
				isAddon,
			},
		}),
	);
}

function* openSidesheetForProduct({ payload }) {
	const {
		productCode,
		relatedSubaccnt,
		domain,
		showConfigure = true,
	} = payload;
	yield put(sideSheetActions.isLoading());

	yield put(
		productDetailsActions.fetch({
			code: productCode,
			alsowith: ['marketplacePrices'],
		}),
	);
	const productDetails = yield take(productDetailsActions.setType);
	const { title, description, features, marketplacePrices } =
		productDetails?.productDetailsPayload || {};

	yield call(openSidesheet, {
		productCode,
		fullDescription: description,
		domain,
		features,
		title,
		relatedSubaccnt,
		showConfigure,
		marketplacePrices,
	});
}

export default function* sideSheetSagas() {
	yield takeLatest(
		marketplaceActions.OPEN_SIDESHEET_FOR_PRODUCT,
		openSidesheetForProduct,
	);

	yield takeLatest(marketplaceActions.OPEN_SIDESHEET, openSidesheet);
}
